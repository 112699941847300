import React, { useState, useContext, useEffect } from "react"
import { navigate } from "gatsby"
import {
  Grid,
  Box,
  Button,
  ButtonBase,
  Card,
  ClickAwayListener,
  Container,
  Divider,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import HomeWorkIcon from "@material-ui/icons/HomeWork"
import { green, purple } from "@material-ui/core/colors"

import gql from "graphql-tag"
import { useQuery, useMutation } from "@apollo/client"

import { useCart } from "../../../components/User/UserCart/Cart"
import UserContext from "../../../context/UserContext"
import { useSiteMetadata } from "../../utils/hooks/useSiteMetadata"
import BigBlackButton from "../../DropIn/BigBlackButton"
import GetShipping from "../../User/UserCart/GetShipping"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const GuestCheckOutBox = ({
  classes,
  cartId,
  uid,
  cartItems,
  started,
  loginRequired,
  checkoutSignIn,
  setCheckoutSignIn,
}) => {
  const { state, dispatch } = useContext(UserContext)
  const isBrowser = () => typeof window !== "undefined"
  const now = new Date().toISOString()
  const [orderId, setOrderId] = useState("")
  const [order, setOrder] = useState(null)
  const [orderItems, setOrderItems] = useState("")
  // const [weight, setWeight] = useState(40)
  // For clickaway listener
  const [cart, setCart] = useState("")
  const [subtotal, setSubtotal] = useState(0.0)
  const [enterCode, setEnterCode] = useState(false)
  // Shipping price on display
  const [shipping, setShipping] = useState(null)
  const [taxCode, setTaxCode] = useState(state.stc)
  const [taxRate, setTaxRate] = useState(0.0)
  const [taxDescription, setTaxDescription] = useState("")
  const [taxName, setTaxName] = useState("")
  const [tax, setTax] = useState(0)
  const [cartUpdated, setCartUpdated] = useState(false)
  const [shippingIsUpdated, setShippingIsUpdated] = useState(false)
  const { sbuId } = useSiteMetadata()
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    totalItems,
    items,
    totalWeight,
    latestUpdate,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    emptyCart,
    isOpen,
    closeCart,
  } = useCart()

  const jsonItems = JSON.stringify(items)

  useEffect(() => {
    !cartUpdated && addOrUpdateCart()
    setCartUpdated(true)
  }, [cartUpdated])

  const handleGoToCheckout = () => {
    addOrUpdateCart()
    setCheckoutSignIn(true)
  }

  const [addOrUpdateCart, { loading, error }] = useMutation(
    CREATE_OR_UPDATE_MY_CART_MUTATION,
    {
      variables: {
        cartId: cartId,
        businessUnitId: sbuId,
        items: jsonItems,
        totalItems: totalItems,
        totalUniqueItems: totalUniqueItems,
        started: started,
        latestChange: latestUpdate,
        cartOpen: true,
        cartExpired: false,
        shippingPostalCode: state.pc,
        shippingToZone: state.sz,
      },
      onCompleted: data => {
        console.log("items")
        console.log(items)
        console.log("cart created or updated")
        console.log(data)
      },
    }
  )

  useEffect(() => {
    isBrowser && setCart(JSON.parse(window.localStorage.getItem("ie-cart")))
  }, [])
  useEffect(() => {
    if (cart) {
      setSubtotal(cart.cartTotal)
    }
  }, [cart])
  const total = shipping ? subtotal + shipping : null

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.title}>Order Summary</Typography>

        <TableContainer>
          <Table className={classes.table} aria-label="spanning table">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography>Subtotal</Typography>
                </TableCell>
                <TableCell align="right">
                  {subtotal ? `${financial(subtotal)}` : ""}
                </TableCell>
              </TableRow>
              <ClickAwayListener onClickAway={() => setEnterCode(false)}>
                <TableRow>
                  <TableCell>
                    <Typography>
                      {enterCode ? "Postal Code" : "Postage"}
                    </Typography>
                    {state.loc ? (
                      <Typography variant="caption">{state.loc}</Typography>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <GetShipping
                      totalWeight={totalWeight}
                      shipping={shipping}
                      setShipping={setShipping}
                      enterCode={enterCode}
                      setEnterCode={setEnterCode}
                      shippingIsUpdated={shippingIsUpdated}
                      setShippingIsUpdated={setShippingIsUpdated}
                      setTaxRate={setTaxRate}
                    />
                  </TableCell>
                </TableRow>
              </ClickAwayListener>
              <TableRow>
                <TableCell>
                  <Typography>Sales Tax</Typography>

                  {taxRate > 0 ? (
                    <Typography variant="caption">{taxRate * 100}%</Typography>
                  ) : (
                    ""
                  )}
                </TableCell>
                <TableCell align="right">
                  {total && `$${financial(total * taxRate)}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
                    Total
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {total && taxRate > 0
                    ? `$${financial(total * (1 + taxRate))}`
                    : total
                    ? `$${financial(total)}`
                    : null}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {!checkoutSignIn && (
          <>
            {loginRequired.length > 0 ? (
              <BigBlackButton
                action={navigate("/my/cart")}
                buttonText="Sign In"
                disabled={isEmpty}
              />
            ) : (
              <BigBlackButton
                action={handleGoToCheckout}
                buttonText="Checkout"
                disabled={isEmpty}
              />
            )}
          </>
        )}
      </Paper>
    </div>
  )
}

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 270,
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "bold",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),

    maxWidth: 400,
  },
  postalInput: {
    maxWidth: 80,
  },
  componentCell: {
    alignItems: "right",
  },
  table: {
    padding: theme.spacing(0),
  },
})

const CREATE_OR_UPDATE_MY_CART_MUTATION = gql`
  mutation (
    $cartId: String
    $items: String
    $totalItems: Int
    $totalUniqueItems: Int
    $started: DateTime
    $latestChange: DateTime
    $businessUnitId: Int
    $cartOpen: Boolean
    $expirationDate: DateTime
    $shippingToZone: String
    $shippingPostalCode: String
  ) {
    createOrUpdateMyCart(
      cartId: $cartId
      items: $items
      totalItems: $totalItems
      totalUniqueItems: $totalUniqueItems
      started: $started
      latestChange: $latestChange
      businessUnitId: $businessUnitId
      cartOpen: $cartOpen
      expirationDate: $expirationDate
      shippingToZone: $shippingToZone
      shippingPostalCode: $shippingPostalCode
    ) {
      cart {
        id
        cartId
        items
        totalItems
        totalUniqueItems
        started
        latestChange
        cartOpen
        expirationDate
        cartExpired
        shippingToZone
        shippingPostalCode
      }
    }
  }
`

export default withStyles(styles)(GuestCheckOutBox)
