import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import clsx from "clsx"

import {
  Box,
  Button,
  Grid,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core"
import {
  PlusCircle as PlusCircleIcon,
  ShoppingCart as ShoppingCartIcon,
} from "react-feather"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  action: {
    marginLeft: theme.spacing(1),
  },
}))

function Header({ className, ...rest }) {
  const classes = useStyles()
  // const { isEmpty } = useCart()

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Grid item>
        <SvgIcon fontSize="small" color="secondary" className={classes.icon}>
          <ShoppingCartIcon />
        </SvgIcon>

        <Typography variant="h5" color="inherit" display="inline">
          Cart
        </Typography>
      </Grid>
      <Grid item>
        {/* <Box display="inline">
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            size="small"
            component={Link}
            to={"/my/items/"}
          >
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <PlusCircleIcon />
            </SvgIcon>
            Login
          </Button>
          <Button
            color="secondary"
            variant="contained"
            className={classes.action}
            size="small"
            component={Link}
            to={"/my/items/"}
          >
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <PlusCircleIcon />
            </SvgIcon>
            Sign Up
          </Button>
        </Box> */}
      </Grid>
    </Grid>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
