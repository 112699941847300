import React, { useState, useEffect, useContext } from "react"
// import gql from "graphql-tag"
// import { useQuery, useMutation } from "@apollo/client"
// import { Page, Cart } from "./PageCart"
import { useCart } from "../components/User/UserCart/Cart"
import { Grid, Box, Container, Typography } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"

// import Loading from "../../Shared/Loading"
// import Error from "../../Shared/Error"
// import SignBackIn from "../../SignBackIn"
import Layout from "../components/Layout/layout"
import Header from "../components/Guest/GuestCheckout/Header"
import GuestCheckOutBox from "../components/Guest/GuestCheckout/GuestCheckOutBox"
import GuestCartDetails from "../components/Guest/GuestCheckout/GuestCartDetails"
// import GuestCheckoutLogin from "../components/Guest/GuestCheckOut/GuestCheckoutLogin"
// import SaveCart from "./SaveCart"
import UserContext from "../context/UserContext"
import { useSiteMetadata } from "../components/utils/hooks/useSiteMetadata"
// import { SAVE_CART_MUTATION, MY_CART_QUERY } from "../../user/UserCart/"
import BigBlackButton from "../components/DropIn/BigBlackButton"

import Sign from "../components/Sign"
import CheckoutAsGuest from "../components/Guest/GuestCheckout/CheckoutAsGuest"
import CheckoutSignIn from "../components/User/UserCart/Checkout/CheckoutSignIn"
import { SettingsBackupRestoreOutlined } from "@material-ui/icons"
const GuestCart = ({ classes }) => {
  const { state, dispatch } = useContext(UserContext)
  const [userStatus, setUserStatus] = useState("anonymous")
  const [checkoutSignIn, setCheckoutSignIn] = useState(false)
  const [loginRequired, setLoginRequired] = useState([])
  const [cart, setCart] = useState("")
  const [cartId, setCartId] = useState("")
  const [items, setItems] = useState("")
  const [started, setStarted] = useState(false)
  const isBrowser = () => typeof window !== "undefined"
  // const [checkoutSignIn, setCheckoutSignIn] = useState(true)
  const isLoggedIn = state.isLoggedIn
  useEffect(() => {
    isBrowser && setCart(JSON.parse(localStorage.getItem("ie-cart")))
  }, [])
  useEffect(() => {
    if (cart) {
      setCartId(cart.id)
      setStarted(cart.started)
    }
  }, [cart])
  // const cartId = cart.id
  // const items = JSON.stringify(cart.items)
  // const started = cart.started

  // const latestChange = cart.latestUpdate
  // const total = cart.cartTotal
  // const totalItems = cart.totalItems
  // const uniqueItems = cart.totalUniqueItems
  // const isOpen = cart.isOpen
  // const isEmpty = cart.isEmpty
  // const [uid, setUid] = useState("")
  // const { sbuId } = useSiteMetadata()

  // const [data, loading, error] = useQuery(MY_CART_QUERY, {
  //   fetchPolicy: "no-cache",
  //   variables: {
  //     sbuId: sbuId,
  //   },
  //   onError: error => {
  //     setUserStatus("anonymous")
  //   },
  //   onCompleted: data => {
  //     // setUid(data.myCart)
  //     if (data && data.myCart) {
  //       setUserStatus("user")
  //       let openCart = data.myCart.cartOpen
  //       let expiredCart = data.myCart.cartExpired
  //       let lastSaved = data.myCart.latestChange
  //       if (
  //         openCart === true &&
  //         expiredCart === false &&
  //         lastSaved >= latestChange
  //       )
  //         console.log("put server cart in local storage")
  //     }
  //   },
  // })

  // useEffect(() => {
  //   {
  //     uid && uid.length > 5 && handleSaveCart()
  //   }
  // }, [uid, total])

  // const [handleSaveCart] = useMutation(SAVE_CART_MUTATION, {
  //   variables: {
  //     cartId: cartId,
  //     businessUnitId: sbuId,
  //     items: items,
  //     totalItems: totalItems,
  //     totalUniqueItems: uniqueItems,
  //     started: started,
  //     latestChange: latestChange,
  //     cartOpen: isOpen,
  //     cartExpired: false,
  //   },

  //   onCompleted: data => {
  //     console.log(data)
  //   },
  // })

  // const handleLogout =()=>{
  //   dispatch({type:"SET_LOGOUT"})

  // }
  // if (loading) return <Loading />
  // if (isLoggedIn === false) return <SignBackIn />
  // if (error) return <Error />
  // if (guestCheckout)
  //   return (
  //     <Layout>
  //       <div className={classes.root}>
  //         <Container maxWidth="lg">
  //           <Box className={classes.guestCheckoutBox}>Guest checkout</Box>
  //         </Container>
  //       </div>
  //     </Layout>
  //   )
  return (
    <Layout>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Header />
          <Box mt={2} mb={2}>
            <Grid container spacing={3} direction="row">
              <Grid
                item
                container
                direction="column"
                xs={12}
                md={8}
                // spacing={3}
              >
                {checkoutSignIn && (
                  <Grid item container spacing={3} direction="row">
                    <Grid item xs={12} sm={12} md={6}>
                      <CheckoutSignIn />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <CheckoutAsGuest />
                    </Grid>
                  </Grid>
                )}

                <Grid item>
                  <GuestCartDetails
                    loginRequired={loginRequired}
                    setLoginRequired={setLoginRequired}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <GuestCheckOutBox
                  cartId={cartId}
                  cartItems={items}
                  started={started}
                  loginRequired={loginRequired}
                  checkoutSignIn={checkoutSignIn}
                  setCheckoutSignIn={setCheckoutSignIn}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </Layout>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: 1,
  },
})

export default withStyles(styles)(GuestCart)
