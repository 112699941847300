import React, { useState } from "react"
import { Box, Divider, Grid, SvgIcon, Typography } from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

import { useCart } from "../../User/UserCart/Cart"
// import UserCartQtySelect from "./UserCartQtySelect"
import CartItemCard from "./CartItemCard"
import BigBlackButton from "../../DropIn/BigBlackButton"
import { ShoppingCart as CartIcon } from "react-feather"
import { navigate } from "gatsby"
function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0)
}
function calcTotal(a, b) {
  return (a * b).toFixed(2)
}

const GuestCartDetails = ({ classes, loginRequired, setLoginRequired }) => {
  const {
    isEmpty,
    cartTotal,
    totalUniqueItems,
    items,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    emptyCart,
  } = useCart()

  const [showLogin, setShowLogin] = useState(false)
  const handleShowLogin = () => {
    setShowLogin(true)
  }
  if (showLogin) navigate("/my/cart")
  if (isEmpty)
    return (
      <div className={classes.root}>
        <Typography variant="subtitle1" className={classes.title}>
          My Cart (0 items)
        </Typography>
        <Box className={classes.signInBox}>
          <SvgIcon
            className={classes.iconBox}
            style={{
              height: "100px",
              width: "100px",
              color: "#949494",
            }}
          >
            <CartIcon />
          </SvgIcon>
          <Box style={{ width: "100%" }}>
            <Typography
              align="center"
              style={{
                fontSize: "20px",
                fontWeight: "lighter",
                marginBottom: "15px",
              }}
            >
              Your cart is currently empty.
            </Typography>
            <Typography
              align="center"
              variant="subtitle1"
              style={{
                marginBottom: "30px",
              }}
            >
              Sign in to see any custom items.
            </Typography>
          </Box>
          <BigBlackButton buttonText="Sign In" action={handleShowLogin} />
        </Box>
      </div>
    )

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            {items ? items.length : "No"}{" "}
            {items.length === 1 ? "item" : "items"} in your cart
          </Typography>
          <Typography variant="h6" className={classes.title}>
            {loginRequired.length > 0
              ? `Login is required to view ${loginRequired.length} ${
                  loginRequired.length === 1 ? "item" : "items"
                }.`
              : ""}
          </Typography>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          {items.map(item => (
            <Box mt={2} key={item.id}>
              <CartItemCard item={item} setLoginRequired={setLoginRequired} />
            </Box>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  root: {
    alignItems: "center",
    margin: theme.spacing(1, 0.5, 0.5, 0.5),
    minWidth: 270,
    boxShadow: "none",
  },
  text: {
    // textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: 260,
    boxShadow: "none",
    elevation: 0,
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    textTransform: "uppercase",

    fontWeight: "bold",
  },
  iconBox: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(3),
  },
  pos: {
    marginBottom: 12,
  },
  signInBox: {
    width: "300px",
    marginTop: theme.spacing(5),
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
})

export default withStyles(styles)(GuestCartDetails)
