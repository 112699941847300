import React from "react"
import {
  Box,
  Divider,
  Grid,
  Paper,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import { navigate } from "gatsby"
import BigBlackButton from "../../../components/DropIn/BigBlackButton"

const CheckoutAsGuest = ({ classes }) => {
  const handleSelectGuestCheckout = event => {
    navigate("/guest/")
  }
  return (
    <div className={classes.root}>
      <Paper square variant="outlined" className={classes.paper}>
        <div className={classes.content}>
          <Box className={classes.info}>
            <Typography className={classes.title}>Guest Checkout</Typography>
            <Typography className={classes.subTitle}>
              With the option to create an account
            </Typography>
          </Box>
          <BigBlackButton
            action={handleSelectGuestCheckout}
            buttonText="Check Out as a Guest"
            disabled={false}
          />
        </div>
      </Paper>
    </div>
  )
}
const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minWidth: 270,
  },
  paper: {
    backgroundColor: "#ffffff",
  },
  content: {
    padding: theme.spacing(2),
    alignItems: "center",
  },
  info: {
    marginBottom: theme.spacing(4),
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "bold",
  },
  subTitle: {
    fontSize: theme.typography.pxToRem(12),
    // textTransform: "uppercase",
    textAlign: "center",
    // fontWeight: "bold",
  },
})

export default withStyles(styles)(CheckoutAsGuest)
