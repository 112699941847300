import React, { useState, useEffect, useContext } from "react"
import gql from "graphql-tag"
import clsx from "clsx"
import { useQuery } from "@apollo/client"
import { withStyles } from "@material-ui/styles"
import { useCart } from "../../User/UserCart/Cart"
import {
  Box,
  ButtonBase,
  CardActionArea,
  CardMedia,
  ClickAwayListener,
  Divider,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  Paper,
  SvgIcon,
  TextField,
  Typography,
} from "@material-ui/core"

import { Link } from "gatsby"
import { XSquare as RemoveSquareIcon } from "react-feather"
import LockIcon from "@material-ui/icons/Lock"
import Loading from "../../utils/Loading"
import Error from "../../utils/Error"

function financial(x) {
  return Number.parseFloat(x).toFixed(2)
}

const CartItemCard = ({ item, loginRequired, setLoginRequired, classes }) => {
  const id = item.id
  const quantity = item.quantity
  const [itemQty, setItemQty] = useState(item.quantity)
  const [itemPrice, setItemPrice] = useState(item.price)
  const [removing, setRemoving] = useState(false)
  const {
    items,
    updateItemQuantity,
    updateItemPrice,
    removeItem,
    totalUniqueItems,
  } = useCart()

  const itemIndex = items.indexOf(item)
  const { data, loading, error } = useQuery(CART_ITEM_QUERY, {
    variables: {
      id: id,
    },
    onCompleted: data => {
      let thisCartItem = data.item
      if (thisCartItem.isPrivate)
        setLoginRequired(items => [...items, thisCartItem])
    },
  })

  useEffect(() => {
    setItemQty(item.quantity)
  }, [])

  useEffect(() => {
    updateItemPrice(id, itemPrice)
  }, [itemPrice])

  if (loading) return <Loading />
  if (error) return <Error />
  if (data.item === null) return <div>Private Item</div>
  if (data.item.isPrivate) {
    // setLoginRequired(loginRequired => [...loginRequired, data.item.id])
    return (
      <>
        <Box className={classes.itemBox}>
          <ButtonBase style={{ width: "100%" }} component={Link} to="/my/cart">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={4} sm={2}>
                <LockIcon style={{ fontSize: "55px", marginLeft: "15px" }} />
              </Grid>
              <Grid item xs={8} sm={10}>
                <Box ml={3}>
                  <Typography>
                    A private or custom item is in your cart.
                  </Typography>
                  <Typography>
                    Please log in to view this item's details.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </ButtonBase>
        </Box>
      </>
    )
  }
  // return <>{JSON.stringify(data)}</>
  const cartItem = data.item
  const priceSet = data.item.itemPrice
  const category = data.item.category.name
  const isPrivate = data.item.isPrivate
  const thumbnail = data.item.featuredImageThumbnailUrl
  const carouselThumb = data.item.featuredImageCarouselImageUrl
  const categoryId = data.item.category && data.item.category.id

  const handleRemoveItem = id => {
    if (removing) {
      // handleRemove()
      removeItem(id)
    } else {
      // const filteredArray = orderItems.filter(x => x.id !== orderItemId)
      // setNewArray(filteredArray)
      setRemoving(true)
    }
  }
  const handleUpdate = event => {
    console.log(event.target.value)
    console.log(priceSet)
    let priceSetId = event.target.value
    var set = priceSet.find(ps => {
      return ps.id === priceSetId
    })
    console.log(set)

    // let price = event.target.value.price

    setItemQty(set.minQuantity)
    updateItemQuantity(id, set.minQuantity)

    setItemPrice(set.price)
  }
  return (
    <>
      {/* <Box className={classes.itemBox}> */}

      <Paper square className={classes.paper}>
        <Box mb={2}>
          <ClickAwayListener onClickAway={() => setRemoving(false)}>
            <IconButton
              aria-label="remove"
              size="small"
              className={
                removing
                  ? clsx(classes.redDelete, classes.margin)
                  : classes.margin
              }
              onClick={() => handleRemoveItem(id)}
            >
              <SvgIcon fontSize="small">
                <RemoveSquareIcon />
              </SvgIcon>
            </IconButton>
          </ClickAwayListener>
        </Box>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          {/* <Grid item xxs={11} xs={11}></Grid>
          <Grid item xxs={1} xs={1}>
            <IconButton
              aria-label="remove"
              size="small"
              className={
                removing
                  ? clsx(classes.redDelete, classes.margin)
                  : classes.margin
              }
              onClick={() => handleRemoveItem(id)}
            >
              <SvgIcon fontSize="small">
                <RemoveSquareIcon />
              </SvgIcon>
            </IconButton>
          </Grid> */}
          <Hidden xsDown>
            <Grid item sm={2}>
              <CardMedia
                className={classes.media}
                image={thumbnail ? thumbnail : undefined}
                // image={carouselThumb ? carouselThumb : undefined}
                title={item.name}
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xxs={12} xs={12}>
              <CardActionArea
                component={Link}
                to={
                  isPrivate
                    ? `/my/items/${id}`
                    : item.urlSlug
                    ? `/${item.urlSlug}`
                    : null
                }
                state={{ id: { id } }}
              >
                <CardMedia
                  className={classes.mobileMedia}
                  // image={thumbnail ? thumbnail : undefined}
                  image={carouselThumb ? carouselThumb : undefined}
                  title={item.name}
                />
              </CardActionArea>
            </Grid>
          </Hidden>
          <Grid item xxs={12} xs={12} sm={4}>
            <Box ml={1} mb={2}>
              <Link
                className={classes.link}
                to={
                  isPrivate
                    ? `/my/items/${id}`
                    : item.urlSlug
                    ? `/${item.urlSlug}`
                    : null
                }
                state={{ id: { id } }}
              >
                <Typography variant="body1">{item.name}</Typography>
              </Link>
              <Typography variant="body2">{category}</Typography>
              <Typography variant="caption">Item # {item.id}</Typography>

              {item.isPrivate && (
                <Typography variant="caption" display="block">
                  Custom Private Item
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={5} sm={2}>
            <Box ml={2}>
              <FormControl>
                <TextField
                  id="qty"
                  fullWidth
                  select
                  size="small"
                  label="Qty"
                  value={quantity}
                  onChange={handleUpdate}
                  variant="outlined"
                  SelectProps={{ native: true, padding: 1 }}
                  InputLabelProps={{ shrink: true }}
                >
                  <option className={classes.selected} selected>
                    {quantity}
                  </option>
                  {priceSet.map(ps => (
                    <option key={ps.id} value={ps.id}>
                      {ps.minQuantity}
                    </option>
                  ))}
                </TextField>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={4} sm={2}>
            ${financial(itemPrice)} ea
          </Grid>
          <Grid item xs={2} sm={1}>
            <Box className={classes.totalBox}>
              ${financial(itemQty * itemPrice)}
            </Box>
          </Grid>
          {/* <Grid item container justify="space-between" xs={2} sm={1}> */}
          {/* <Grid item></Grid> */}
          {/* <Grid
              item
              container
              direction="column"
              justify="space-between"
              alignItems="flex-end"
            > */}
          {/* <Grid item>
                <IconButton
                  aria-label="remove"
                  size="small"
                  className={
                    removing
                      ? clsx(classes.redDelete, classes.margin)
                      : classes.margin
                  }
                  onClick={() => handleRemoveItem(id)}
                >
                  <SvgIcon fontSize="small">
                    <RemoveSquareIcon />
                  </SvgIcon>
                </IconButton>
              </Grid> */}
          {/* <Grid item>
                <Box className={classes.totalBox}>
                  ${financial(itemQty * itemPrice)}
                </Box>
              </Grid> */}
          {/* </Grid> */}
          {/* </Grid> */}
          {/* {item.taxAmt > 0 && (
              <>
                <Grid item xs={9}>
                  Tax
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.taxAmt)}
                </Grid>
              </>
            )}
            {item.discountAmt > 0 && (
              <>
                <Grid item xs={9}>
                  Discount
                </Grid>
                <Grid item xs={3}>
                  ${financial(oi.discountAmt)}
                </Grid>
              </>
            )} */}

          {/* <Grid item xs={9}>
              Item total
            </Grid> */}
          {/* <Grid item xs={3}>
              total */}
          {/* ${financial(oi.itemSubtotal)} */}
          {/* </Grid> */}
          {/* </Grid> */}
        </Grid>
        {/* </Box> */}
      </Paper>
      {itemIndex + 1 < totalUniqueItems && <Divider />}
    </>
  )
}
const CART_ITEM_QUERY = gql`
  query($id: Int) {
    item(id: $id) {
      id
      name
      isPrivate
      category {
        id
        name
      }
      pricingModel {
        id
        name
      }
      featuredImageThumbnailUrl
      featuredImageCarouselImageUrl
      specItem {
        unitWeightEach
        weightUnits
      }
      itemPrice {
        id
        minQuantity
        price
      }
    }
  }
`

const styles = theme => ({
  root: {
    margin: theme.spacing(0, 0.5, 0.5, 0.5),
    minWidth: 225,
    boxShadow: "none",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  selected: {
    display: "none",
  },
  redDelete: {
    color: "#FF0000",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.common.black,
  },
  mobileMedia: {
    height: "190px",
    // maxWidth: "190px",
    maxWidth: "95%",
    backgroundColor: theme.palette.background.dark,
    margin: theme.spacing(0, 2),
  },
  media: {
    height: "80px",
    width: "80px",
    backgroundColor: theme.palette.background.dark,
    margin: theme.spacing(0, 2),
  },
  itemBox: {
    border: "1px solid #A9A9A9",
    padding: theme.spacing(1),
  },
  rightBox: {
    position: "relative",
  },

  totalBox: {
    // marginTop: "100%",
  },
})
export default withStyles(styles)(CartItemCard)
